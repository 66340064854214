import GraphQLRequest from '../services/graphql.service'
import APIRequest from '../services/api.service'

const imports = {
  currentImport: clientId => {
    return dispatch => {
      dispatch({ type: 'CURRENT_IMPORT' })
      return APIRequest.get(dispatch, `clients/${clientId}/manualimports/currentImport`)
        .then(res => {
          return dispatch({
            type: 'CURRENT_IMPORT_SUCCESS',
            import: res.body.data
          })
        })
        .catch(err => {
          dispatch({ type: 'CURRENT_IMPORT_FAILURE', error: err.message })
          throw err
        })
    }
  },

  export: clientId => {
    return dispatch => {
      dispatch({ type: 'EXPORT_DATA_DUMP_STARTED' })
      return APIRequest.get(dispatch, `clients/${clientId}/exports/datadump`)
        .then(res => {
          return dispatch({
            type: 'EXPORT_DATA_DUMP_SUCCESS',
            data: res.text
          })
        })
        .catch(err => {
          dispatch({ type: 'EXPORT_DATA_DUMP_FAILURE', error: err.message })
          throw err
        })
    }
  },

  get: clientId => {
    return dispatch => {
      dispatch({ type: 'IMPORT_SUMMARY' })
      return APIRequest.get(dispatch, `clients/${clientId}/manualimports`)
        .then(res => {
          return dispatch({
            type: 'IMPORT_SUMMARY_SUCCESS',
            summary: res.body.data
          })
        })
        .catch(err => {
          console.error('could not get import summary', err.message)
          dispatch({ type: 'IMPORT_SUMMARY_FAILURE', error: err.message })
          throw err
        })
    }
  },

  misGuardianEmailsXlsDownload: () => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `{
          misGuardianEmailsXlsDownload {
            filename
            url
            type
            queued
          }
        }`
      }

      return graphQLRequest.send(query)
    }
  },

  misImportXlsDownload: (importId) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `query importLogDownload($input: ImportLogDownloadInput!) {
          importLogDownload(input: $input) {
            filename
            url
            type
            queued
          }
        }`,
        variables: { input: { import_id: importId } }
      }

      return graphQLRequest.send(query)
    }
  },

  update: (clientId, file) => {
    const formData = new FormData()
    formData.append('csv_import', file)

    return dispatch => {
      dispatch({ type: 'IMPORT' })
      return APIRequest.post(dispatch, `clients/${clientId}/manualimports`, formData)
        .then(res => {
          return dispatch({
            type: 'IMPORT_STARTED',
            currentImport: res.body.data
          })
        })
        .catch(err => {
          console.error('failed to import', err.message)
          dispatch({ type: 'IMPORT_FAILURE', error: err.message })
          throw err
        })
    }
  },

  xporterFullSync: () => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          mutation xporterFullSync {
            xporterFullSync
          }
        `
      }

      return graphQLRequest.send(query)
    }
  }
}

export default imports
